<template>
  <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header position-fixed">
      <h5 id="offcanvasRightLabel"></h5>
      <button type="button" class="btn-close btn-close-white text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <div class="row h-100 align-content-center">
        <div class="col-12 h-50 d-flex justify-content-center align-items-center flex-column">
          <div class="row">
            <div class="col-6 text-center m-auto pb-3">
              <img :src="`/img/ui-theme/alex-jokovich.jpg`" alt="Alex Jokovich" class="rounded-circle img-fluid" />
            </div>
            <div class="col-12 text-center">
              <h4 class="text-white">Contact Alex Jokovich</h4>
            </div>
          </div>
          <div class="row w-100">
            <div class="col-6 col-sm-4 m-auto d-flex justify-content-evenly">
              <a href="mailto:sales@yvesmermaidbeach.com.au"><img :src="`/img/ui-theme/email-icon.svg`" alt="Email"></a>
              <a href="tel:0432 846 501" class="d-lg-none"><img :src="`/img/ui-theme/phone-icon.svg`" alt="Contact"></a>
            </div>
          </div>
          <div class="row w-100">
            <div class="col-6 col-sm-4 m-auto d-flex justify-content-evenly d-none d-lg-block text-center p-2">
              <a href="tel:0432 846 501" class="phone"><h4>0432 846 501</h4></a>
            </div>
          </div>
          <div class="row w-100">
            <div class="col-12 py-4 text-center">
              <h3>Or</h3>
            </div>
          </div>
          <div class="row w-100">
            <div class="col-4 m-auto text-center">
              <a class="btn-theme-menu" href="https://yvesmermaidbeach.com.au/#enquire">Enquire</a>
            </div>
          </div>
          <div class="row w-100">
            <div class="col-12 pt-3 text-center">
              <h4>Alex will contact you</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OffCanvas',
  props: [
    'heading'
  ],
  data() {
    return {
    }
  }
};
</script>

<style scoped>
.btn-theme-menu {
  width: 60%;
  background: #fff;
  color: rgba(19, 32, 60, 1.0);
  font-weight: 500;
  margin: auto;
}
.phone {
  color: #fff;
  text-decoration: none;
}
</style>