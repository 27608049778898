<template>
  <div class="col-12 ps-3 pe-3 d-flex align-items-center justify-content-end theme-header">
    <router-link :to="{ name: 'DashboardHome'}" class="btn-back"></router-link>
    <p class="m-auto">{{ this.displayHeading }}</p>
    <button class="btn d-lg-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"><img src="/img/ui-theme/contact-icon.svg" alt="Fullscreen"></button>
  </div>
</template>

<script>
import global from '../global'
import { unslugify } from "unslugify";

export default {
  name: 'TypeTopNav',
  props: [
    'heading'
  ],
  setup() {
    const {theme} = global
    let displayHeading = ""

    return {
      theme, displayHeading
    }
  },
  created() {
    this.displayHeading = unslugify(this.heading)
  }
};
</script>