<template>
  <!-- Top navigation -->
  <DetailTopNav :heading="this.viewType" :subheading="this.assetType" />

  <!-- Range -->
  <div class="position-absolute v-content d-flex align-items-center text-center z-1" v-if="viewType == 'renders' && this.assetTypesReady === true">
    <div class="col-12 h-100 p-0 z-1">
      <div class="row h-100 g-0">
        <div class="col-12 h-100 position-relative z-1">
          <img :src="theme.cdnUrl + '/assets/' + assetType + '/' + viewType + '/' + this.assetTypes[this.order].LinkName" class="h-100">
        </div>
      </div>
    </div>
  </div>

  <!-- Panoramas -->
  <div class="position-absolute v-content d-flex align-items-center text-center z-1" v-if="viewType == 'panoramas' || viewType == 'virtual-tour' && this.assetTypesReady === true">
    <iframe :src="this.assetTypes[this.order].url" :title="this.assetTypes[this.order].title" width="100%" height="100%" allow="fullscreen"></iframe>
  </div>

  <!-- Floorplans -->
  <div class="position-absolute v-content d-flex align-items-center text-center z-1" v-if="viewType == 'floorplans' && this.assetTypesReady === true">
    <div class="col-12 h-100 p-0 z-1">
      <div class="row h-100 g-0">
        <div class="col-12 h-100 position-relative z-1">
          <img :src="theme.cdnUrl + '/assets/' + assetType + '/' + viewType + '/' + this.assetTypes[this.order].LinkName" class="h-100">
        </div>
      </div>
    </div>
  </div>
  <OffCanvas />
</template>

<script>
import DetailTopNav from '@/components/DetailTopNav.vue'
import OffCanvas from "@/components/OffCanvas.vue"
import PdfView from "./PdfView.vue"
import global from "../global"
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";

export default {
  name: "AssetViewDetail",
  props: [
    'assetType',
    'viewType',
    'order'
  ],
  components: {
    DetailTopNav,
    PdfView,
    OffCanvas
  },
  data() {
    return {
      assetTypes: null,
      assetOrder: null,
      currentAsset: null,
      folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/' + this.assetType + '/' + this.viewType + '/'),
      currentIndex: null,
      assetNames: null,
      assetUrls: null,
      componentName: "AssetView",
      assetTypesReady: false,
      pdfAPIReady: false,
      pdfSource: '',
    }
  },
  created() {
    if (this.assetType === 'introduction') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.introductionAsset.videos
        this.assetNames = this.theme.introductionAsset.videosName
      } else if (this.viewType === 'brochures') {
        this.assetOrder = this.theme.introductionAsset.brochures
        this.assetNames = this.theme.introductionAsset.brochuresName
        this.assetUrls = this.theme.introductionAsset.brochuresUrl
      }
    } else if (this.assetType === 'penthouse') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.penthouseAsset.videos
        this.assetNames = this.theme.penthouseAsset.videosName
      } else if (this.viewType === 'renders' || this.viewType === 'range') {
        this.assetOrder = this.theme.penthouseAsset.renders
        this.assetNames = this.theme.penthouseAsset.rendersName
        this.assetUrls = this.theme.penthouseAsset.rendersUrl
      } else if (this.viewType === 'panoramas' || this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.penthouseAsset.panoramas
        this.assetNames = this.theme.penthouseAsset.panoramasName
        this.assetUrls = this.theme.penthouseAsset.panoramasUrl
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.penthouseAsset.floorplans
        this.assetNames = this.theme.penthouseAsset.floorplansName
        this.assetUrls = this.theme.penthouseAsset.floorplansUrl
      }
    } else if (this.assetType === 'sub-penthouse') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.subPenthouseAsset.videos
        this.assetNames = this.theme.subPenthouseAsset.videosName
      } else if (this.viewType === 'renders' || this.viewType === 'range') {
        this.assetOrder = this.theme.subPenthouseAsset.renders
        this.assetNames = this.theme.subPenthouseAsset.rendersName
        this.assetUrls = this.theme.subPenthouseAsset.rendersUrl
      } else if (this.viewType === 'panoramas' || this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.subPenthouseAsset.panoramas
        this.assetNames = this.theme.subPenthouseAsset.panoramasName
        this.assetUrls = this.theme.subPenthouseAsset.panoramasUrl
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.subPenthouseAsset.floorplans
        this.assetNames = this.theme.subPenthouseAsset.floorplansName
        this.assetUrls = this.theme.subPenthouseAsset.floorplansUrl
      }
    } else if (this.assetType === '3-bed-apartment-type-k') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.bed3TypeKAsset.videos
        this.assetNames = this.theme.bed3TypeKAsset.videosName
      } else if (this.viewType === 'renders' || this.viewType === 'range') {
        this.assetOrder = this.theme.bed3TypeKAsset.renders
        this.assetNames = this.theme.bed3TypeKAsset.rendersName
        this.assetUrls = this.theme.bed3TypeKAsset.rendersUrl
      } else if (this.viewType === 'panoramas' || this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.bed3TypeKAsset.panoramas
        this.assetNames = this.theme.bed3TypeKAsset.panoramasName
        this.assetUrls = this.theme.bed3TypeKAsset.panoramasUrl
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.bed3TypeKAsset.floorplans
        this.assetNames = this.theme.bed3TypeKAsset.floorplansName
        this.assetUrls = this.theme.bed3TypeKAsset.floorplansUrl
      }
    } else if (this.assetType === '3-bed-apartment-type-l') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.bed3TypeLAsset.videos
        this.assetNames = this.theme.bed3TypeLAsset.videosName
      } else if (this.viewType === 'renders' || this.viewType === 'range') {
        this.assetOrder = this.theme.bed3TypeLAsset.renders
        this.assetNames = this.theme.bed3TypeLAsset.rendersName
        this.assetUrls = this.theme.bed3TypeLAsset.rendersUrl
      } else if (this.viewType === 'panoramas' || this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.bed3TypeLAsset.panoramas
        this.assetNames = this.theme.bed3TypeLAsset.panoramasName
        this.assetUrls = this.theme.bed3TypeLAsset.panoramasUrl
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.bed3TypeLAsset.floorplans
        this.assetNames = this.theme.bed3TypeLAsset.floorplansName
        this.assetUrls = this.theme.bed3TypeLAsset.floorplansUrl
      }
    } else if (this.assetType === '2-bed-apartment') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.bed2Asset.videos
        this.assetNames = this.theme.bed2Asset.videosName
      } else if (this.viewType === 'renders' || this.viewType === 'range') {
        this.assetOrder = this.theme.bed2Asset.renders
        this.assetNames = this.theme.bed2Asset.rendersName
        this.assetUrls = this.theme.bed2Asset.rendersUrl
      } else if (this.viewType === 'panoramas' || this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.bed2Asset.panoramas
        this.assetNames = this.theme.bed2Asset.panoramasName
        this.assetUrls = this.theme.bed2Asset.panoramasUrl
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.bed2Asset.floorplans
        this.assetNames = this.theme.bed2Asset.floorplansName
        this.assetUrls = this.theme.bed2Asset.floorplansUrl
      }
    } else if (this.assetType === 'amenities') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.introductionAsset.videos
        this.assetNames = this.theme.introductionAsset.videosName
      } else if (this.viewType === 'renders' || this.viewType === 'range') {
        this.assetOrder = this.theme.introductionAsset.renders
        this.assetNames = this.theme.introductionAsset.rendersName
        this.assetUrls = this.theme.introductionAsset.rendersUrl
      }
    }

    BunnyNetService.getRenders(this.folderStructure, this.assetType)
    .then(response => {
      let j = 0;
      // Remap array
      const menuItems = response.data.map((item) => {
        return {
          Guid: item.Guid,
          ObjectName: unslugify(item.ObjectName),
          LinkName: item.ObjectName,
          Path: item.Path,
          IsDirectory: item.IsDirectory,
          StorageZoneName: item.StorageZoneName,
          title: this.assetNames[j],
          url: this.assetUrls[j],
          id: j++
        };
      });

      // Custom order array based on section and type
      if( this.assetOrder ) {
        this.assetTypes = this.reorder(menuItems, this.assetOrder);
      } else {
        this.assetTypes = menuItems
      }

      this.assetTypesReady = true;

      if (this.viewType === 'brochures') {
        this.pdfFilename();
      }
    })
  },
  methods: {
    // Reorder array based on config setup
    reorder: function(data_array, real_order) {
      var i, d = {}, result = [];
      for(i=0; i<data_array.length; ++i) {
        d[data_array[i].id] = data_array[i];
      }

      for(i=0; i<real_order.length; ++i) {
        result.push(d[real_order[i]]);
      }

      return result;
    },

    // Assign PDF filename and display name
    pdfFilename: function() {
      console.log(this.assetTypes[this.order])
      this.fileTemp = this.assetTypes[this.order].LinkName;
      this.file = this.fileTemp.substr(0, this.fileTemp.lastIndexOf(".")) + ".pdf";
      this.fileName = this.assetTypes[this.order].ObjectName;
      this.fileName = this.fileName.substr(0, this.fileName.lastIndexOf(".")) + ".pdf";

      this.pdfSource = this.theme.cdnUrl + '/assets/' + this.assetType + '/' + this.viewType + "/pdfs/" + this.file;
      console.log(this.pdfSource);
    },
  },
  setup () {
    const {theme} = global

    return {
      theme
    }
  }
}
</script>