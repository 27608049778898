import { reactive } from "vue";

const theme = reactive({
  agentLogo: "hirsch-faigen-footer-logo.svg",
  devLogo: "yves-header-logo.svg",
  apiUrl: "https://api.metaluxe.com.au/",
  bunnyApiUrl: "https://api.bunny.net/",
  accessKey: "ec558f34-7538-4b7e-b822-179253457e6b93932c0d-eae6-47b4-b444-1ac035b8ff7f",
  streamToken: "0067a98f-d930-4bae-912e-ec931a105d9d",
  videoLibraryId: "49583",
  collectionId: "7f127b7b-1db8-4ffa-9319-4672ba5cfef9",
  streamCdnUrl: "https://vz-0995692e-15f.b-cdn.net",
  streamApiKey: "9536a6ec-0c7f-46c8-adab7303920d-b496-426f",
  storageApiPassword: "78ceaa5c-9753-4c34-adc254fe7501-d62f-4e52",
  cdnUrl: "https://yves.metaluxe.com.au",
  devFolder: "/yves",
  videosIcon: "/img/ui-theme/videos-icon.svg",
  rendersIcon: "/img/ui-theme/renders-icon.svg",
  panoramasIcon: "/img/ui-theme/panoramas-icon.svg",
  floorplansIcon: "/img/ui-theme/floorplans-icon.svg",
  dashboardMp4: "theme/dashboard/4K_dashboard.mp4",
  dashboardWebm: "theme/dashboard/4K_dashboard.webm",
  assetsMp4: "theme/dashboard/4K_assets.mp4",
  assetsWebm: "theme/dashboard/4K_assets.webm",
  
  // top level menu
  assetsMenuOrder: [
    'introduction',
    'penthouse',
    'sub-penthouse',
    '3-bed-apartment-type-k',
    '3-bed-apartment-type-l',
    '2-bed-apartment',
    'amenities'
  ],

  // asset level menu
  introductionMenuOrder: [
    'videos'
  ],
  
  penthouseMenuOrder: [
    'videos',
    'virtual-tour',
    'floorplans',
    'renders'
  ],

  subPenthouseMenuOrder: [
    'videos',
    'virtual-tour',
    'floorplans',
    'renders'
  ],

  bed3TypeKMenuOrder: [
    'videos',
    'virtual-tour',
    'floorplans',
    'renders'
  ],
  
  bed3TypeLMenuOrder: [
    'videos',
    'virtual-tour',
    'floorplans',
    'renders'
  ],

  bed2MenuOrder: [
    'videos',
    'virtual-tour',
    'floorplans',
    'renders'
  ],

  amenitiesMenuOrder: [
    'videos',
    'renders'
  ],
  
  // introduction asset order
  introductionAsset: {
    videos: [ 0 ],
    videosName: [
      'YVES Introduction'
    ],
    renders: [ 0 ],
    rendersName: [ 
      '',
    ],
    rendersUrl: [ 
      '',
    ],
    panoramas: [ 0 ],
    panoramasName: [ 
      ''
    ],
    panoramasUrl: [ 
      ''
    ],
    floorplans: [ 0 ],
    floorplansName: [ 
      ''
    ],
    floorplansUrl: [ 
      ''
    ],
    brochures: [ 0 ],
    brochuresName: [ 
      'YVES Brochure'
    ],
    brochuresUrl: [ 
      ''
    ]
  },
  
  // penthouse asset order
  penthouseAsset: {
    videos: [ 1, 0 ],
    videosName: [
      'Walkthrough',
      'Cinematic'
    ],
    renders: [ 0, 1, 6, 3, 5, 4, 2 ],
    rendersName: [
      'Balcony',
      'Cellar',
      'Master suite',
      'Dining',
      'Master ensuite',
      'Lounge',
      'Dining cellar'
    ],
    rendersUrl: [
      '',
      '',
      '',
      '',
      '',
      '',
      ''
    ],
    panoramas: [ 0 ],
    panoramasName: [ 
      'Penthouse virtual tour'
    ],
    panoramasUrl: [ 
      'https://app.cloudpano.com/tours/OVWo0qb_x?mls=1'
    ],
    floorplans: [ 0 ],
    floorplansName: [ 
      'Penthouse floorplan'
    ],
    floorplansUrl: [ 
      ''
    ]
  },
  
  // sub penthouse asset order
  subPenthouseAsset: {
    videos: [ 1, 0 ],
    videosName: [
      'Walkthrough',
      'Cinematic'
    ],
    renders: [ 0, 1, 2, 3, 4 ],
    rendersName: [ 
      'Balcony',
      'Bathroom',
      'Dining',
      'Kitchen',
      'Master bedroom'
    ],
    rendersUrl: [ 
      '',
      '',
      '',
      '',
      ''
    ],
    panoramas: [ 0 ],
    panoramasName: [ 
      'Sub penthouse virtual tour'
    ],
    panoramasUrl: [ 
      'https://app.cloudpano.com/tours/CtdIBtulH?mls=1'
    ],
    floorplans: [ 0 ],
    floorplansName: [ 
      'Sub penthouse floorplan'
    ],
    floorplansUrl: [ 
      ''
    ]
  },
  
  // 3 bedroom asset order
  bed3TypeKAsset: {
    videos: [ 0 ],
    videosName: [
      'Walkthrough',
    ],
    videosUrl: [
        ''
    ],
    renders: [ 0, 1, 2, 3, 4, 6, 5, 7, 8, 9 ],
    rendersName: [ 
        'Balcony',
        'Bathroom',
        'Bedroom_2',
        'Bedroom_3_Balcony',
        'Bedroom_3',
        'Dining',
        'Living',
        'Living 2',
        'Master Bedroom',
        'Master Ensuite'
    ],
    rendersUrl: [
      'Master bedroom'
    ],
    floorplans: [ 0 ],
    floorplansName: [
      '3 Bed apartment - Type K',
    ],
    floorplansUrl: [
      ''
    ],
    panoramas: [ 0 ],
    panoramasName: [ 
      '3 Bed apartment - Type K virtual tour'
    ],
    panoramasUrl: [ 
      'https://app.cloudpano.com/tours/wGEdWQSSv'
    ]
  },
  // 3 bedroom asset order
  bed3TypeLAsset: {
    videos: [ 1 , 0],
    videosName: [
      'Walkthrough',
      'Cinematic'
    ],
    videosUrl: [
        '',
        ''
    ],
    renders: [ 0, 1, 2, 3, 4, 5 ],
    rendersName: [ 
        'Balcony bedroom',
        'Balcony',
        'Bathroom',
        'Kitchen',
        'Living',
        'Master Bedroom'
    ],
    rendersUrl: [
      'Master bedroom'
    ],
    floorplans: [ 0 ],
    floorplansName: [
      '3 Bed apartment - Type L',
    ],
    floorplansUrl: [
      ''
    ]
  },

  // 2 bedroom asset order
  bed2Asset: {
    videos: [ 0 ],
    videosName: [
      'Cinematic'
    ],
    renders: [ 0 ],
    rendersName: [ 
      ''
    ],
    rendersUrl: [ 
      ''
    ],
    floorplans: [ 0, 1, 2, 3, 4, 5 ],
    floorplansName: [ 
      '2 Bed apartment - Type A',
      '2 Bed apartment - Type D',
      '2 Bed apartment - Type E',
      '2 Bed apartment - Type F',
      '2 Bed apartment - Type G',
      '2 Bed apartment - Type N'
    ],
    floorplansUrl: [
      '',
      '',
      '',
      '',
      '',
      ''
    ],
    panoramas: [ 0 ],
    panoramasName: [ 
      '2 Bed apartment - virtual tour'
    ],
    panoramasUrl: [ 
      'https://app.cloudpano.com/tours/bjiQwHpa2'
    ],
  },

  // amenitiesasset order
  amenitiesAsset: {
    videos: [ 0 ],
    videosName: [
      'Walkthrough'
    ],
    renders: [ 0, 1, 2, 3, 4 ],
    rendersName: [ 
      'Casual dining',
      'Formal dining',
      'Lounge',
      'Pool entrance',
      'Pool'
    ],
    rendersUrl: [ 
      '',
      '',
      '',
      '',
      ''
    ]
  }
});

export default { theme };