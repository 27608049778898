<template>
  <div class="app-header">
    <template v-if="isLoading">
      Loading...
    </template>

    <template v-else>
      <span v-if="showAllPages">
        {{ pageCount }} page(s)
      </span>

      <span v-else>
        <button :disabled="page <= 1" @click="page--">❮</button>
        {{ page }} / {{ pageCount }}
        <button :disabled="page >= pageCount" @click="page++">❯</button>
      </span>

      <label class="right">
        <input v-model="showAllPages" type="checkbox">
        Show all pages
      </label>
    </template>
  </div>

  <div class="app-content">
    <vue-pdf-embed
      ref="pdfRef"
      :source="pdfSource"
      :page="page"
      @password-requested="handlePasswordRequest"
      @rendered="handleDocumentRender"
    />
  </div>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed'

export default {
  name: "PdfView",
  components: {
    VuePdfEmbed
  },
  props: [
    'pdfSource'
  ],
  data() {
    return {
      isLoading: true,
      page: null,
      pageCount: 1,
      pdfSource: this.pdfSource,
      showAllPages: true,
    }
  },
  watch: {
    showAllPages() {
      this.page = this.showAllPages ? null : 1
    },
  },
  methods: {
    handleDocumentRender() {
      this.isLoading = false
      this.pageCount = this.$refs.pdfRef.pageCount
    },
    handlePasswordRequest(callback, retry) {
      callback(prompt(retry
        ? 'Enter password again'
        : 'Enter password'
      ))
    },
  }
}
</script>

<style scoped>
.vue-pdf-embed > div {
  margin-bottom: 8px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}

.app-header {
  padding: 16px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
  color: #fff;
}

.app-content {
  padding: 0px 16px 60px 16px;
}

.right {
  float: right;
}
</style>